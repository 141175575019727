import styled from 'styled-components';
import breakpoint, {map} from 'styled-components-breakpoint'

// answer li
export const AnswerWrap = styled.li`
${tw`px-4 pb-4 display-flex mb-5`};
transition: all 500ms ease-in-out;
position: relative;

  ${breakpoint('md')`
  font-size: 20px;
  `}

  &.hide-me {
      opacity: 0;
      &:nth-child(1) {
          transition-delay: 100;
      }
      &:nth-child(2) {
          transition-delay: 150;
      }
  }

.option {
  display: flex;
  cursor: pointer;
  &__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      padding: 0;
  }
  &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

}

.option__input:checked + .option__text:before {
  background-color:  ${props => props.theme.colors.checkBoxBorder};
}
.option__input:checked + .option__text:after {
  transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0.7);
}

span.option__text {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 2em;
      line-height: 1.2;
      text-align: left;

    &:before {
      background-color: transparent;
      width: 1.3em;
      height: 1.3em;
      border: 2px solid  ${props => props.theme.colors.checkBoxBorder};
      border-radius: 0.2em;
      content: "";
    }
    &:after {
      width: 0.5em;
      height: 1em;
      background-color: transparent;
      border-bottom: 3px solid #011023;
      border-right: 3px solid #011023;
      transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0);
      content: "";
    }
    &:before,
    &:after {
        left: 0;
        position: absolute;
    }
   
}
`
// Error

export const ErrorMsg = styled.div`
    padding: 8px 20px;
    color: #fff;
    border: 1px solid ${props => props.theme.colors.wrongAnswerColor};
    max-width: 630px;
    width: 100%;
    margin: 0 auto;
`

// Custom Checkbox with Label
export const LabelWrap = styled.label`
    display: flex;
    padding: 2rem 0;
    cursor: pointer;
    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        padding: 0;
    }
  .option__input:checked + .option__text:before {
    background-color: ${props => props.theme.colors.checkBoxBorder};
  }
  .option__input:checked + .option__text:after {
    transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0.7);
  }
  span.option__text {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 2em;
        line-height: 1.2;
        text-align: left;
        font-size: 14px;

      &:before {
        background-color: transparent;
        width: 1.3em;
        height: 1.3em;
        border: 2px solid ${props => props.theme.colors.checkBoxBorder};
        border-radius: 0.2em;
        content: "";
      }
      &:after {
        width: 0.5em;
        height: 1em;
        background-color: transparent;
        border-bottom: 3px solid #011023;
        border-right: 3px solid #011023;
        transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0);
        content: "";
      }
      &:before,
      &:after {
          left: 0;
          position: absolute;
      }
     
  }
`

export const FormFieldStyle = styled.div`
  font-family: ${props => props.theme.fonts};
  width: 100%;
`

export const InputFieldStyle = styled.input`
 ${tw`mb-2 display-block w-100 rounded-sm text-base text-sm py-2 px-4 border-gray-400 focus:border-gray-600 border-1`};
 width: 100%;
 border: 1px solid #eee;
`


export const H1Wrap = styled.div`
  
  max-width: 620px;
  margin: 0 auto;


   ${props => props.noborder === true && css`
   border-bottom: none;
   color: orange;
   `}

   border: ${props => props.noborder === true ? `1px solid #fff;` : "none" };

   
  ${tw`px-4 py-4 text-center mb-6`};
  

  h1 {
    font-family: ${props => props.theme.fonts};
    font-weight: 600;
    font-size: 30px;
    line-height: 1.1;
    ${tw`mb-4`};
    ${breakpoint('md')`
        font-size: 40px;
    `}
  
  }
  p {
      display: block;
      font-weight: 400;
      ${tw` text-gray-600`};
      text-transform: none;
      margin-bottom: 30px;
      font-size: 15px;
      ${breakpoint('md')`
        font-size: 20px;
    `}
  }
`


export const ContentWrap = styled.div`
  ${tw`font-sans text-center flex-1`};
  font-family: ${props => props.theme.fonts};
  display: flex;
  flex-direction: column;
`

export const WrapDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

.lisensiert-logo {
    display: none;
    ${breakpoint('xl')`
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        max-width: 100%;
        height: auto;
        display: block;
    `}
}

`


export const LogoWrapper = styled.div`
position: absolute;
text-align: center;
padding: 1rem 0;
width: 100%;
a {
    display: inline-block;
    svg path.white {
           fill:  ${props => props.route === 'quiz-route-home' ? '#333' : 'var(--LogoPathFill,#fff)' };
           @media (min-width: 1200px) {
            fill:  ${props => props.route === 'quiz-route-home' ? '#333' : 'var(--LogoPathFill,#333)' };
           }
        }
    ${breakpoint('md')`
        path.white {
           fill:  ${props => props.route === 'quiz-route-home' ? '#333' : 'red' }
        }
    `}
}
img {
    max-width: 150px;
    height: auto;
    margin: 0;
}

@media (min-width: 1200px) {
        position: fixed;
        top: 1rem;
        left: 1rem;
        text-align: left;
        padding: 0;
        width: auto;
    }

    
`

// MainWrapper
export const MainWrapper = styled.div`
    background: ${props => props.theme.colors.quizContainerBackground};
    color: ${props => props.theme.colors.textColor};
    width: 100%;
    margin: 0 auto;
    flex: 1;
    padding: 6rem 2rem;
    max-width: 767px;
    @media (min-width: 767px) {
        padding: 4rem 2rem;
    }
    a {
        color: ${props => props.theme.colors.linkColor};
        transition: all 200ms ease-in-out;
        &:hover,
        &:focus {
            color: ${props => props.theme.colors.linkColorHover};
        }
    }
`

export const NotificationMsgWrap = styled.div`

    ${tw`py-3 text-white`};

    ${breakpoint('md')`
        font-size: 24px;
    `}

    span {
        color: ${props => props.error ? props.theme.colors.wrongAnswerColor : props.theme.colors.correctAnswerColor};
    }
  
    animation: ${props => props.error ? "error 1s ease forwards" : "none"};

 
  @keyframes error {
  from,
  to {
            transform: translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
            transform: translate3d(-10px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
            transform: translate3d(10px, 0, 0); }
  from {
    opacity: 0;
            transform: scale(0.97); }
  25% {
            transform: scale(1);
    opacity: 1; } }

`

export const QuizWrapperDiv = styled.div`
  ${tw`border-0 text-white px-6 py-6`};
  background: ${props => props.theme.colors.quizContainerBackground};
  width: 100%;
  max-width: ${props => props.theme.widths.containerWidth};
  margin: 0 auto;
  flex: 1;
  a {
    color: ${props => props.theme.colors.linkColor};
    &:hover,
    &:focus {
       color: ${props => props.theme.colors.linkColorHover};
    }
  }
`

export const QuizWrapperInner = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: ${props => props.theme.widths.containerWidth};
    flex: 1;
`

export const YoutubeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const UnorderedList = styled.ul`
  ${tw`pt-4 mb-3 list-none`};
  li {
      ${tw`list-none`}
  }
`

export const TermsStyle = styled.div`
text-align: left;
max-width: 480px;
margin: 0 auto;
font-weight: normal;

ul, ol {
    li {
        margin-bottom: 0.5rem;
        &::marker {
            color: ${props => props.theme.colors.primaryColor };
        }
    }
}

`