import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
        >
        {children}
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`

&:before,
&:after {
        background-position: top right !important;
        background-repeat: no-repeat !important;
        background-attachment: scroll !important;
        min-height: 100vh !important;
        background-size: 280% !important;
   
    @media (min-width: 767px) {
        background-size: cover !important;
        background-position: top center !important;  
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
    }
    }
`

export default StyledBackgroundSection