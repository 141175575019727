import React from 'react'
import PropTypes from 'prop-types'
import Theme from "../theme"
import Logo from './logo'
import Seo from "../components/seo"
import lisensiert from '../images/lisensiert-logo.png';
import GlobalStyle from '../styles/globalstyles';
import { getRoute } from '../helpers.js';
import { WrapDiv, ContentWrap} from '../styles/common'
import useCampaignQuery from '../graphql/queries'
import BackgroundSection from '../components/background-image'

export default function Layout({children}) {

    const {site, sanityCampaign } = useCampaignQuery();
    
    return (


      <>
       <Seo 
            title={sanityCampaign.siteTitle}
            description={sanityCampaign.siteDescription}
            image={sanityCampaign.socialImage}>
        </Seo>
       
        <Theme>
            <GlobalStyle />
            <BackgroundSection>
            <WrapDiv>
            
                <Logo link={site.siteMetadata.logoUrl} desc={site.siteMetadata.logoDesc} route={getRoute()} />
                <ContentWrap>{children}</ContentWrap>
                <img src={lisensiert} alt="" className="lisensiert-logo" />
            </WrapDiv>
            </BackgroundSection>
        </Theme>
      </>
  )}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}