import { createGlobalStyle } from 'styled-components'
import bg from '../images/bg.jpg'
import { LogoWrapper } from './common'
const GlobalStyle = createGlobalStyle`

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

p, ul, ol {
    margin-bottom: 1rem;
}

.item {
    position: relative;
}

.fade-enter{
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
}

.fade-enter.fade-enter-active{
    opacity: 1;
    visibility: visible;
    transition: all ease 1s;
}

.fade-exit {
    visibility: visible;
    opacity: 0;
}

.fade-exit.fade-exit-active{
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
}

body.quiz-route-home {
    --LogoBreakPoint: 2000px;
    --LogoPathFill: #333;
    ${LogoWrapper} {
        @media (min-width: 767px) {
        position: absolute;
            top: 1rem;
            left: 1rem;
            text-align: left;
            padding: 0;
            width: auto;
    }
        @media (min-width: 1200px) {
            position: absolute;
            top: 1rem;
            left: 1rem;
            text-align: left;
            padding: 0;
            width: auto;
        }
    }
}


  `;
 
export default GlobalStyle;