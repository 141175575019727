import { graphql, useStaticQuery } from "gatsby"

// TODO: use env var, doesnt work with static query... :'(

const useCampaignQuery = () => {
  const QueryData = useStaticQuery(graphql`
  query MyQuery {
    site {
        siteMetadata {
          title,
          logoUrl,
          logoDesc,
        }
      }
    sanityCampaign(_id: {eq: "667904f5-8392-454d-b670-40c8565682c3" }) {
      name
    _rawTerms
    
    siteTitle
        siteDescription
        socialImage {
          asset {
            url
          }
        }
              winners {
                week
                name
              }  
              questions {
                _key
                text
                introText
                imageCaption
                image {
                    asset {
                        fluid(maxWidth: 700) {
                          ...GatsbySanityImageFluid
                        }
                      }
                }
                answers {
                  isCorrect
                  text
                  reason
                  _key
                }
              }
    }
     premie: file(relativePath: {eq: "spilleriet-premie.png"}) {
                id
                childImageSharp {
                  fixed(quality: 100) {
                      base64
                      tracedSVG
                      aspectRatio
                      srcWebp
                      srcSetWebp
                      originalName
                      src
                      srcSet
                    }
                }
              }
     iphonehand: file(relativePath: {eq: "hand-med-telefon.png"}) {
                  id
                  childImageSharp {
                    fixed(quality: 100) {
                        base64
                        tracedSVG
                        aspectRatio
                        srcWebp
                        srcSetWebp
                        originalName
                        src
                        srcSet
                      }
                  }
                }
  }
  
  `)
  return QueryData
}

export default useCampaignQuery;