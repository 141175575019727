import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.png'
import { getRoute } from '../helpers.js';

function SEO({ description, lang, meta, title, image }) {

  let img = "";
  if (image !== null) {
      img = image.asset.url
  }
  
  const metaDescription = description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      links={[
        {"rel": "icon", 
         "type": "image/png", 
         "href": {favicon}
        }
       ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
            property: `og:image`,
            content: img,
          },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <body className={getRoute()} />
        </Helmet>
  )
}
SEO.defaultProps = {
  lang: `no`,
  meta: [],
  description: ``,
  image: null,
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
}
export default SEO