import React from 'react'
import PropTypes from 'prop-types'
import LogoSvg from "../images/svg/logo.inline.svg"
import { LogoWrapper } from '../styles/common'

const LogoButton = ({ link, desc }) => (
    <LogoWrapper>
          <a href={link} title={desc}>
        
          <LogoSvg />
        </a>
    </LogoWrapper>
)

export default LogoButton

LogoButton.propTypes = {
    link: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
}