import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primaryColor: "#EE7E05",
    secondaryColor: "#06B49A",
    backgroundColor: '#ccc',
    textColor: '#fff',
    quizContainerBackground: "#202223",
    checkBoxBorder: "#E07000",
    checkBoxColor: "#fff",
    linkColor: "#fff",
    linkColorHover: "#E07000",
    buttonColor: "#111",
    buttonBorderColor: "#EE7E05",
    buttonTextColor: "#202223",
    buttonColorHover: "#EE7E05",
    buttonColorSecondary: '#fff',
    wrongAnswerColor: "#f53a3a",
    correctAnswerColor: "green",
    headerColor: "#fff",
    headerColorSmall: "#fff",
  },
  widths: {
    containerWidth: "720px"
  },
  fonts: ["Montserrat, sans-serif"],
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;