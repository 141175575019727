import React from 'react'
import PropTypes from 'prop-types'
import { MainWrapper } from '../styles/common'

export default function MainWrap({children}) {
    return(
        <MainWrapper>
        {children}
        </MainWrapper>
    )
};

MainWrap.propTypes = {
    children: PropTypes.array.isRequired,
}